import React from "react"
import { ThemeProvider } from "styled-components"
import config from "../../theme.config"

export const theme = {
  ...config.colors,
  ...config.screens,
}

const AppThemeProvider = (props) => <ThemeProvider theme={theme} {...props} />

export default AppThemeProvider
