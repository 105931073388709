import React from "react"
import AppThemeProvider from "~theme"
import { OverlayProvider } from "@react-aria/overlays"
import { DidomiProvider } from "~components/Didomi/DidomiContext"
import { DidomiBadge } from "~components/Didomi"

const LayoutWrapper = ({ children, ...props }) => {
  return (
    <AppThemeProvider {...props}>
      <DidomiProvider>
        <OverlayProvider>{children}</OverlayProvider>
        <DidomiBadge />
      </DidomiProvider>
    </AppThemeProvider>
  )
}

export default LayoutWrapper
